@import 'variables';
@import 'tachyons';
@import 'colors';
@import 'typography';
@import 'extra';
@import 'testimonials';
@import 'organizations';
@import 'calendarheatmap';
@import 'datepicker';
@import 'accordion';
@import 'learn';
@import 'notifications';
@import 'contributions';
@import 'osmus';

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.active {
  opacity: 1;
}

.categorycard:hover > svg *,
.categorycard:hover {
  fill: #d73f3f;
}
